export default {
  name: "RoleProvider",
  props: {
    //kullanıcının hangi role ile işlem yapacağını aldığımız props
    slug: {
      type: String,
      required: true,
    },
  },
  render() {
    //kullanıcı rollerini ata
    const userRoles = this.$store.state.GetServerData.signularRules;
    //propstan gelen custom slug ata
    const controllerSlug = this.slug;

    return this.$scopedSlots.default({
      canItPass: userRoles.includes(controllerSlug) || false,
    });
  },
};
