<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard class="topCard">
          <CCardHeader class="topCardHeader">
            <div class="d-flex justify-content-between align-items-center">
              <div class="h4 m-0">Partner Kullanıcıları</div>
              <div class="d-flex justify-content-end" style="min-width: 550px">
                <input
                  type="text"
                  v-model="searchFilterInput"
                  class="form-control mr-3"
                  style="max-width: 250px"
                  autocomplete="new"
                  placeholder="Arama..."
                  v-on:keyup="onFilterTextBoxChanged()"
                />
                <RoleProvider slug="PARTNER_USER_CREATE">
                  <div slot-scope="{ canItPass }">
                    <CButton
                      color="light"
                      variant="outline"
                      v-if="canItPass"
                      size="sm"
                      class="py-2 px-3 font-weight-bold"
                      @click="clickOpenModalButton(`new`)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'plus']"
                        class="mr-2"
                      />Yeni Ekle
                    </CButton>
                  </div>
                </RoleProvider>
              </div>
            </div>
          </CCardHeader>
          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="
                (params) => clickOpenModalButton(`old`, params)
              "
              @grid-ready="onGridReady"
            ></ag-grid-vue>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      size="lg"
      class="vendorModalAddUpdateForm modalNoBorder"
      :title="modal.title"
      :show.sync="modal.show"
    >
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-4 m-0">
            <CRow>
              <CCol md="4">
                <CInput v-model="form.name" placeholder="İsim" label="İsim" />
              </CCol>
              <CCol md="4">
                <CInput
                  v-model="form.lastname"
                  placeholder="Soy İsim"
                  label="Soy İsim"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  v-model="form.username"
                  placeholder="Kullanıcı Adı"
                  label="Kullanıcı Adı"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CSelect
                  :value.sync="form.status"
                  v-model="form.status"
                  placeholder="Durum Seçiniz"
                  label="Durum"
                  :options="constants.statusOptions"
                />
              </CCol>
              <CCol>
                <CSelect
                  :value.sync="form.isApiUser"
                  v-model="form.isApiUser"
                  placeholder="Api Durumu"
                  label="Api Durumu"
                  :options="constants.isApiUserOptions"
                />
              </CCol>
              <CCol>
                <CSelect
                  :value.sync="form.partnerID"
                  v-model="form.partnerID"
                  :placeholder="
                    modal.action === `put` ? form.partnerID?.title : `Firma`
                  "
                  label="Firma"
                  :options="constants.partnerIdOptions"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  v-model="form.email"
                  placeholder="E-Posta"
                  label="E-Posta"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  v-model="form.password"
                  label="Şifre"
                  :placeholder="modal.action == 'put' ? '********' : 'Şifre'"
                  type="password"
                  autocomplete="new-password"
                />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>

      <template #footer>
        <RoleProvider slug="PARTNER_USER_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              @click="deletePartnerAxios"
              color="danger"
              v-if="modal.action == `put` && canItPass"
              >Sil</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider
          :slug="
            modal.action == `put`
              ? `PARTNER_USER_UPDATE`
              : `PARTNER_USER_CREATE`
          "
        >
          <div slot-scope="{ canItPass }">
            <CButton
              @click="addOrUpdateAxios"
              v-if="canItPass"
              color="success"
              >{{ modal.action === "post" ? "Ekle" : "Değiştir" }}</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import { RoleProvider } from "../../provider";
import { userPartnersColumnsDefs } from "../../columnDefs";
var _ = require("lodash");
Vue.use(VueSweetalert2);
const formInitialState = () => ({
  _id: "",
  email: "",
  isApiUser: null,
  lastname: "",
  name: "",
  partnerID: "",
  status: "",
  username: "",
  password: "",
});
export default {
  name: "Partners",
  components: {
    AgGridVue,
    RoleProvider,
  },
  data() {
    return {
      searchFilterInput: null,
      columnDefs: userPartnersColumnsDefs,
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
            text: "asdas",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      rowSelection: "multiple",
      rowData: [],
      form: formInitialState(),
      constants: {
        statusOptions: [
          {
            value: "Active",
            label: "Aktif",
          },
          {
            value: "InActive",
            label: "Pasif",
          },
          {
            value: "Pending",
            label: "Bekliyor",
          },
          {
            value: "Banned",
            label: "Engellendi",
          },
        ],
        isApiUserOptions: [
          {
            value: false,
            label: "Yok",
          },
          {
            value: true,
            label: "Var",
          },
        ],
        partnerIdOptions: [],
      },
      modal: {
        action: "",
        title: "",
        show: false,
      },
    };
  },

  created() {
    this.getPartners();
    this.getUserPartners();
  },

  methods: {
    async addOrUpdateAxios() {
      //request type
      const requestType = this.modal.action;

      //if action post delete _id
      if (requestType == "post") delete this.form._id;
      try {
        await axios[requestType](
          process.env.VUE_APP_API_URL + "admin/partner/user",
          this.form
        );
        this.alert("Başarılı", "success");
        this.resetWindow();
        setTimeout(() => this.$router.go(), 1000);
      } catch (error) {
        this.alert(
          error?.response?.data?.message || "Lütfen bilgileri kontrol ediniz.",
          "error"
        );
      } finally {
        this.$Progress.finish();
      }
    },
    async deletePartnerAxios() {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu Partner kullanıcısını silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await axios.delete(
                process.env.VUE_APP_API_URL + "admin/partner/user",
                body
              );
              this.alert("Başarılı", "success");
              setTimeout(() => this.$router.go(), 1000);
            } catch (error) {
              this.alert("Lütfen bilgileri kontrol ediniz.", "error");
            } finally {
              this.$Progress.finish();
            }
          }
        });
      const body = { data: { _id: this.form._id } };
    },
    async getPartners() {
      this.$Progress.start();
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/partner"
        );
        this.constants.partnerIdOptions = data.map((d) => ({
          value: d._id,
          label: d.title,
        }));
      } catch (error) {
        this.alert("Lütfen bilgileri kontrol ediniz.");
      } finally {
        this.$Progress.finish();
      }
    },
    async getUserPartners() {
      this.$Progress.start();
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/partner/user"
        );
        this.rowData = _.reverse(data);
      } catch (error) {
        this.alert("Lütfen bilgileri kontrol ediniz.");
      } finally {
        this.$Progress.finish();
      }
    },
    resetWindow() {
      Object.assign(this.form, formInitialState());
    },
    clickOpenModalButton(btn, params) {
      btn == "new"
        ? ((this.modal = {
            title: "Yeni Partner Kullanıcı Ekle",
            action: "post",
            show: true,
          }),
          this.resetWindow())
        : btn == "old"
        ? ((this.modal = {
            title: "Partner Kullanıcı Güncelle",
            action: "put",
            show: true,
          }),
          (this.form = params.data))
        : null;
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    alert(title, icon) {
      Vue.swal({
        icon: icon ? icon : "success",
        title,
        toast: true,
        position: "bottom-start",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Vue.swal.stopTimer);
          toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
        },
      });
    },
  },
};
</script>
